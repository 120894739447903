import React from 'react'

import { FavouritesPage } from '../components/FavouritesPage'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Router } from '@reach/router'

const Favourites = () => {
  return (
    <Layout generic>
      <SEO title="Favourites" />

      <Router basepath="/favourites">
        <FavouritesPage path="/" />
        <FavouritesPage path="/:id" shared />
      </Router>
    </Layout>
  )
}

export default Favourites
